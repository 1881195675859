import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {CoreDirectivesModule} from '@core/directives/directives';
import {CorePipesModule} from '@core/pipes/pipes.module';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {NgSelectModule} from '@ng-select/ng-select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {NgxUiLoaderModule} from 'ngx-ui-loader';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';
import {NgxSpinnerModule} from 'ngx-spinner';
import {MatInputModule} from '@angular/material/input';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import {CsvModule} from '@ctrl/ngx-csv';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';

@NgModule({
    imports: [CommonModule, FlexLayoutModule, FormsModule, ReactiveFormsModule, CoreDirectivesModule, CorePipesModule,
        MatDatepickerModule,
        MatNativeDateModule,
        NgSelectModule,
        MatCheckboxModule,
        MatDialogModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        NgxUiLoaderModule,
        NgxSkeletonLoaderModule,
        NgxSpinnerModule,
        MatInputModule,
        NgxDatatableModule,
        NgbDropdownModule,
        CsvModule,
        MatIconModule,
        MatMenuModule
    ],
    exports: [
        CommonModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        CoreDirectivesModule,
        CorePipesModule,
        MatDatepickerModule,
        MatNativeDateModule,
        NgSelectModule,
        MatCheckboxModule,
        MatDialogModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        NgxUiLoaderModule,
        NgxSkeletonLoaderModule,
        NgxSpinnerModule,
        MatInputModule,
        NgxDatatableModule,
        NgbDropdownModule,
        CsvModule,
        MatIconModule,
        MatMenuModule
    ]
})
export class CoreCommonModule {
}
