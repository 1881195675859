import {CoreMenu} from '@core/types';
import {Role} from '../auth/models';

export const menu: CoreMenu[] = [
    {
        id: 'home',
        title: 'Home',
        translate: 'MENU.HOME',
        type: 'item',
        icon: 'home',
        url: 'home'
    },
    {
        id: 'tenants',
        title: 'Tenants',
        translate: 'MENU.TENANTS',
        type: 'item',
        icon: 'file',
        url: 'modules/tenants',
        role: [Role.super_admin]

    },
    {
        id: 'plans',
        title: 'Plans',
        translate: 'MENU.PLANS',
        type: 'item',
        icon: 'file',
        url: 'modules/plans',
        role: [Role.super_admin]

    },
    {
        id: 'users',
        title: 'Users',
        translate: 'MENU.USERS',
        type: 'item',
        icon: 'file',
        url: 'modules/users',
        role: [Role.super_admin]
    },
    {
        id: 'pageBuilder',
        title: 'Page Builder',
        translate: 'MENU.PAGE_BUILDER',
        type: 'item',
        icon: 'file',
        url: 'modules/page-builder',
        role: [Role.super_admin]
    }
];
