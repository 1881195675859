import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {AuthenticationService} from 'app/auth/service';
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    /**
     * @param {Router} _router
     * @param {AuthenticationService} _authenticationService
     */
    constructor(private _router: Router, private _authenticationService: AuthenticationService, private _toastrService: ToastrService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            map(res => {
                if (res['status'] == 200) {
                    this._toastrService.success(
                        res['body']?.message,
                        '👋 Success',
                        {
                            timeOut: 2000,
                            positionClass: 'toast-top-full-width',
                            toastClass: 'toast ngx-toastr bg-white toast-success',
                            titleClass: 'toast-title mx-4 text-dark',
                            messageClass: 'text-dark', closeButton: true
                        }
                    );
                }
                return res;
            }),
            catchError(err => {
                console.log(err);
                console.log(Object.values(err.error.errors));
                const keys: any = Object.values(err.error.errors);
                keys.forEach(e => {
                    this._toastrService.error(
                        e[0],
                        'Error',
                        {
                            timeOut: 2000,
                            positionClass: 'toast-top-full-width',
                            toastClass: 'toast ngx-toastr  toast-error',
                            titleClass: 'toast-title mx-4 text-dark',
                            messageClass: 'text-dark', closeButton: true
                        }
                    );
                });
                if ([401, 403].indexOf(err.status) !== -1) {
                    // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                    this._router.navigate(['/auth/miscellaneous/not-authorized']);

                    // ? Can also logout and reload if needed
                    // this._authenticationService.logout();
                    // location.reload(true);
                }
                // throwError
                const error = err.error.message || err.statusText;
                return throwError(error);
            })
        );
    }
}
