import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {environment} from 'environments/environment';
import {User, Role} from 'app/auth/models';
import {ToastrService} from 'ngx-toastr';

@Injectable({providedIn: 'root'})
export class AuthenticationService {
    //public
    public currentUser: Observable<User>;

    //private
    private currentUserSubject: BehaviorSubject<User> = new BehaviorSubject<User>(null);
    public apiUrl: any = environment.apiUrl;

    /**
     *
     * @param {HttpClient} _http
     * @param {ToastrService} _toastrService
     */
    constructor(private _http: HttpClient, private _toastrService: ToastrService) {
        if (localStorage.getItem('currentUser') == 'undefined') {
            localStorage.removeItem('currentUser');
        }
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')) as User);
        this.currentUser = this.currentUserSubject.asObservable();
    }

    // getter: currentUserValue
    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    /**
     *  Confirms if user is admin
     */
    get isAdmin() {
        return this.currentUser && this.currentUserSubject.value.role === Role.Admin;
    }

    /**
     *  Confirms if user is client
     */
    get isClient() {
        return this.currentUser && this.currentUserSubject.value.role === Role.Client;
    }

    /**
     * User login
     *
     * @param email
     * @param password
     * @returns user
     */
    verifyOtp(opt: any) {
        return this._http
            .post<any>(this.apiUrl + `/v1/api/lms/mobile-login`, {opt})
            .pipe(
                map(user => {
                    console.log(user);
                    // login successful if there's a jwt token in the response
                    if (user && user.token) {
                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        localStorage.setItem('currentUser', JSON.stringify(user));

                        // Display welcome toast!
                        setTimeout(() => {
                            this._toastrService.success(
                                'You have successfully logged in as an ' +
                                user.role +
                                ' user to Vuexy. Now you can start to explore. Enjoy! 🎉',
                                '👋 Welcome, ' + user.firstName + '!',
                                {toastClass: 'toast ngx-toastr', closeButton: true}
                            );
                        }, 2500);

                        // notify
                        this.currentUserSubject.next(user);
                    }

                    return user;
                })
            );
    }

    register(form: any) {
        return this._http
            .post<any>(`${this.apiUrl}/v1/api/lms/register`, form)
            .pipe(
                map(data => {
                    // login successful if there's a jwt token in the response
                    if (data && data.status_code == 200) {
                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        localStorage.setItem('segment', JSON.stringify(data.data.domain));

                        // Display welcome toast!
                        setTimeout(() => {
                            this._toastrService.success(
                                'You Will Redirected To ' +
                                data.data.domain +
                                ' Now you can start to explore. Enjoy! 🎉',
                                '👋 Welcome, To Academeyat',
                                {toastClass: 'toast ngx-toastr', closeButton: true}
                            );
                        }, 2500);

                        // notify
                    }
                    return data;
                }));
    }

    loginWithMobile(mobile: string) {
        console.log(environment.apiUrl);
        return this._http
            .post<any>(this.apiUrl + `/dashboard/send-opt`, {mobile})
            .pipe(
                map(user => {
                    console.log(user);
                    localStorage.setItem('currentUser', JSON.stringify(user.data));
                    this.currentUserSubject.next(user.data);
                    return user;
                })
            );
    }

    sendOtpToEmail(email: string) {
        return this._http
            .post<any>(this.apiUrl + `/dashboard/send-email-opt`, {email})
            .pipe(
                map(user => {
                    localStorage.setItem('currentUser', JSON.stringify(user.data));
                    this.currentUserSubject.next(user.data);
                    return user;
                })
            );
    }

    loginWithEmail(email, password): Observable<User> {
        return this._http
            .post<any>(this.apiUrl + `/dashboard/login`, {email, password})
            .pipe(
                map(user => {
                    const newUser: any = {...user.data.user, role: user.data.role, token: user.data.token};
                    localStorage.setItem('currentUser', JSON.stringify(newUser));
                    this.currentUserSubject.next(newUser);
                    return user;
                })
            );
    }

    verifyOtpFromEmail(opt): Observable<User> {
        return this._http
            .post<any>(this.apiUrl + `/dashboard/email-login`, {opt})
            .pipe(
                map(user => {
                    const newUser: any = {...user.data.user, role: user.data.role, token: user.data.token};
                    localStorage.setItem('currentUser', JSON.stringify(newUser));
                    this.currentUserSubject.next(newUser);
                    return user;
                })
            );
    }
    checkDomain(domain: string) {
        const headers = new HttpHeaders().set('Accept', 'application/json');
        return this._http
            .post<any>(`${this.apiUrl}/accounts/check-domain`, {domain}, {headers})
            .pipe(
                map(data => {
                    // login successful if there's a jwt token in the response
                    if (data && data.status_code == 200) {
                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        localStorage.setItem('segment', JSON.stringify(data.data.domain));

                        // Display welcome toast!
                        setTimeout(() => {
                            this._toastrService.success(
                                'You Will Redirected To ' +
                                data.data.domain +
                                ' Now you can start to explore. Enjoy! 🎉',
                                '👋 Welcome, To Membire',
                                {toastClass: 'toast ngx-toastr', closeButton: true}
                            );
                        }, 2500);

                        // notify
                    }
                    return data;
                })
            );
    }

    registerAsTenant(form: any) {
        const headers = new HttpHeaders().set('Accept', 'application/json');
        return this._http
            .post<any>(`${this.apiUrl}/accounts/register`, form, {headers})
            .pipe(
                map(data => {
                    // login successful if there's a jwt token in the response
                    if (data && data.status_code == 200) {
                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        localStorage.setItem('segment', JSON.stringify(data.data.domain));

                        // Display welcome toast!
                        setTimeout(() => {
                            this._toastrService.success(
                                'You Will Redirected To ' +
                                data.data.domain +
                                ' Now you can start to explore. Enjoy! 🎉',
                                '👋 Welcome, To Membire',
                                {toastClass: 'toast ngx-toastr', closeButton: true}
                            );
                        }, 2500);

                        // notify
                    }
                    return data;
                }));
    }
    /**
     * User logout
     *
     */
    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        // notify
        this.currentUserSubject.next(null);
    }
}
