export const locale = {
  lang: 'en',
  data: {
    MENU: {
      HOME: 'Home',
      SAMPLE: 'Sample',
      TENANTS: 'Tenants',
      PLANS: 'Plans',
      USERS: 'Users',
      PAGE_BUILDER: 'Page Builder',
    },
  }
}
